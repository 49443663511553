<template>
  <div class="form-wrap">
      <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal"/>
      <Loading v-if="loading"/>
      <form class="login" @submit.prevent="resetPassword">
          <h2>密码重置</h2>
          <div class="inputs">
                <div class="input">
                    <input type="password" placeholder="新密码" v-model="newPassword">
                    <password class="icon"/>
                </div>
                <div class="input">
                    <input type="password" placeholder="密码确认" v-model="passwordConfirmed">
                    <password class="icon"/>
                </div>
                <div v-show="error" class="error">
                    {{this.errorMsg}}
                </div>
          </div>
          <button>密码重置</button>
          <div class="angle"></div>        
      </form>
      <div class="background"></div>
  </div>
</template>

<script>
import Modal from "../components/Modal"
import Loading from "../components/Loading"
import password from "../assets/Icons/lock-alt-solid.svg"

export default {
    
    data(){
        return{
            newPassword:null,
            passwordConfirmed:null,
            error:null,
            errorMsg:"",
            modalActive:false,
            modalMessage:"",
            loading:null,
        }
    },
    components:{
        password,
        Modal,
        Loading,
    },
    methods:{
        async resetPassword(){
            //https://www.mywebsite.com/forgotpwchange?email=2017070256@njupt.edu.cn&evpw=VVGuRvWCJasPEgtjiwmjOiLOemyb6xpiTH9GArpCYoSuk5DevuNTXElrQfZecJbv
            console.log(this.$route.query)
            if (this.newPassword != this.passwordConfirmed){
                this.error = true;
                this.errorMsg = "密码不一致"
                return
            }

            var that = this
            this.loading = true;
            await this.$store.dispatch('resetPassword',{
            email:that.$route.query.email,
            password:that.newPassword,
            evpw:that.$route.query.evpw,

            })
            .then(()=>{
                this.error = false;
                this.errorMsg = "";
                this.modalActive = true;
                this.modalMessage = "密码修改成功，即将为您跳转至登录界面！"
                this.loading = false;
            })
            .catch((err)=>{
                this.error = true;
                this.errorMsg = err.response.data.message;
                this.loading = false;
                console.log(this.errorMsg)
            })
        },
        closeModal(){
            this.modalActive = !this.modalActive;
            this.newPassword = null;
            this.passwordConfirmed = null;
            this.$router.push({name:'Login'})
        }

    }
}
</script>



